const content = {
    "Publications": [
        {
            text: "Rahul Zalkikar and Kanchan Chandra (2024).",
            title: "Measuring Social Biases in Masked Language Models by Proxy of Prediction Quality.",
            journal: "Under review.",
            pdfLink: process.env.PUBLIC_URL + "/papers/BiasMLMs.pdf",
            codeLink: "https://github.com/zalkikar/mlm-bias",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
        {
            text: "Kanchan Chandra and Rahul Zalkikar (2023).",
            title: "An Analysis of Implicit and Explicit Mentions in Trump Tweets.",
            journal: "Under review.",
            pdfLink: "",
            codeLink: "",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
        {
            text: "Kevin Driscoll and Rahul Zalkikar (2019).",
            title: "The Gender Binary of Computing: Challenging Sexism in Technology.",
            journal: "Culture Digitally.",
            pdfLink: "",
            codeLink: "",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
    ],
    "Projects": [
        {
            text: "Limitation Learning: Capturing Adverse Dialog with GAIL (2021).",
            title: "Generative adversarial imitation learning to produce a proxy for the reward function present in dialogue.",
            pdfLink: process.env.PUBLIC_URL + "/papers/LimitationLearning.pdf",
            codeLink: "",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
        {
            text: "Synthesizing baseball data with event prediction pretraining (2020).",
            title: "Classifying in-game baseball events with GRU, LSTM autoencoders and position tracking data.",
            pdfLink: process.env.PUBLIC_URL + "/papers/PositionTracking.pdf",
            codeLink: "",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
        {
            text: "Boundary box creation using a GradCAM heat-map from a pre-trained image classification model (2020).",
            title: "Improving IoU for bbox creation with vision contouring from grad-CAM heatmap weighted by trained image classifier.",
            pdfLink: "",
            codeLink: "https://github.com/zalkikar/BBOX_GradCAM",
            titleColor: "#3399cc",
            pdfColor: "grey",
        },
    ],
};

export default content;