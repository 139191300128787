import React, { useState } from "react";
import styled from "styled-components";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { Sun, Moon } from "react-feather";

const Header = ({darkMode,setDarkMode}) => {
    function handleMode() {
        var dm = !darkMode;
        localStorage.setItem("darkMode", dm);
        setDarkMode(dm)
    }
    return (
        <div style={{display: "flex", flexDirection: "row",width: "90%", justifyContent: "right", alignItems: "center", gap: "6px",}}>
            <Sun size={22} color={darkMode ? "white" : "black"}/>
            <Toggle
                defaultChecked={darkMode !== undefined && darkMode !== null ? darkMode : false}
                onChange={handleMode}
                icons={false}
            />
            <Moon size={22} color={darkMode ? "white" : "black"}/>
        </div>
    );
};

export default Header;