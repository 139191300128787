import React, { Fragment, useState } from "react";
import { Document, Page } from "react-pdf";
import styled, { keyframes } from "styled-components";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { theme } from "../../Theme";
import { ChevronsRight, ChevronsLeft, X } from "react-feather";
import { ColorRing } from "react-loader-spinner";

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const PreviewContainer = styled.div`
    background: #101010;
    border: 1px solid #303030;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 600px;
    padding: 1rem;
    z-index: 1;
    overflow-y: auto;
`
const ModalOverlay = styled.a`
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

const ModalBlock = styled.div`
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    opacity: 1;
    z-index: 400;
    -webkit-animation: ${fadein} 0.5s linear;
`;

const PageButton = styled.button`
    border: 10px solid transparent;
    cursor: pointer;
    background: transparent;
    :disabled {
        opacity: 0 !important;
    }
`

function PdfPreviewer({ pdfFile, active, setActive, darkMode, }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Fragment>
        {active && (
            <ModalBlock>
                <ModalOverlay onClick={() => { setActive(false) }}/>
                <PreviewContainer>
                    <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "right", }}>
                        <X size={22} color={'grey'} cursor={"pointer"} onClick={() => {setActive(false)}}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center", gap: "12px"}}>
                        {pageNumber <=1 ? <div style={{width: "52px"}}/> :<PageButton
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            <ChevronsLeft size={22} color={'grey'}/>
                        </PageButton>}
                        <span style={{fontSize: "12px", color: "lightgrey"}}>
                            Page {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                        </span>
                        {pageNumber >= numPages ? <div style={{width: "52px"}}/> : <PageButton
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            <ChevronsRight size={22} color={'grey'}/>
                        </PageButton>}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center", overflowY: "auto", padding: "82px 0 0 0",borderRadius: 0}}>
                        <Document
                            file={pdfFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} loading={
                            <div style={{background: "#101010", width: "100%", }}>
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={["grey","grey","grey","grey","grey","grey"]}
                                />
                            </div>
                            }/>
                        </Document>
                    </div>
                </PreviewContainer>
            </ModalBlock>
        )}
    </Fragment>
  );
};

export default PdfPreviewer;