import React, {useEffect} from "react"
import { HelpCircle, Mail } from 'react-feather'
import { FaDiscord, FaTwitter, FaMedium, FaTelegram, FaGithub } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { TWITTER_LINK, DISCORD_LINK, DOCS_LINK, MEDIUM_LINK, TERMS_LINK, EMAIL, TELEGRAM_LINK, GITHUB_LINK, } from '../../constants'
import Link from "../Link"
import styled from 'styled-components'

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

const SubMenuTitleWrap = styled.div`
    padding: 22px 8px;
`

const SubMenuTitle = styled.span`
    color: black; //#25adea;
    &:hover {
        color: #303030;
    }
`

const Socials = ({iconColor}) => {
    const [windowDimenion, detectHW] = React.useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);
        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimenion]);
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",}}>
            <SubMenu>
                <SubMenuTitleWrap>
                    <div class="mailto"
                        onClick={(e) => {
                        window.location.href = `mailto:${EMAIL}`;
                        e.preventDefault();
                        }}
                        style={{
                            cursor: "pointer"
                        }}>
                        <SubMenuTitle>
                            <MdEmail size={22} color={iconColor} />
                        </SubMenuTitle>
                    </div>
                </SubMenuTitleWrap>
            </SubMenu>
            {/*<SubMenu>
                <SubMenuTitleWrap>
                    <Link href={`${DISCORD_LINK}`} target="_blank">
                        <SubMenuTitle>
                            <FaDiscord size={22} color={iconColor} />
                        </SubMenuTitle>
                    </Link>
                </SubMenuTitleWrap>
            </SubMenu>*/}
            <SubMenu>
                <SubMenuTitleWrap>
                    <Link href={`${TWITTER_LINK}`} target="_blank">
                        <SubMenuTitle>
                            <FaTwitter size={22} color={iconColor} />
                        </SubMenuTitle>
                    </Link>
                </SubMenuTitleWrap>
            </SubMenu>
            {/*<SubMenu>
                <SubMenuTitleWrap>
                    <Link href={`${MEDIUM_LINK}`} target="_blank">
                        <SubMenuTitle>
                            <FaMedium size={22} color={iconColor} />
                        </SubMenuTitle>
                    </Link>
                </SubMenuTitleWrap>
            </SubMenu>*/}
            <SubMenu>
                <SubMenuTitleWrap>
                    <Link href={`${GITHUB_LINK}`} target="_blank">
                        <SubMenuTitle>
                            <FaGithub size={22} color={iconColor} />
                        </SubMenuTitle>
                    </Link>
                </SubMenuTitleWrap>
            </SubMenu>
            {/*<SubMenu>
                <SubMenuTitleWrap>
                    <Link href={`${TELEGRAM_LINK}`} target="_blank">
                        <SubMenuTitle>
                            <FaTelegram size={22} color={iconColor} />
                        </SubMenuTitle>
                    </Link>
                </SubMenuTitleWrap>
            </SubMenu>*/}
        </div>
    )
}

export default Socials;